import {
  Button,
  Container,
  Group,
  ThemeIcon
} from '@mantine/core';
import { useLocation } from '@reach/router';
import { IconRefresh } from '@tabler/icons-react';
import cx from 'clsx';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../components/AppContext/AppContext';
import Logo from '../../components/Logo/Logo';
import { useWindowSize } from '../../hooks/useWindowSize';
import UserAvatar from '../UserAvatar/UserAvatar';
import * as classes from './Header.module.css';


const Header = () => { 

  const [isLogin, setIsLogin] = useState<boolean>();

  useEffect(() => {
    const jwtToken = Cookies.get("jwtToken");
    setIsLogin(!!jwtToken);
  }, [Cookies.get("jwtToken")]);


  const handleRefresh = () =>{
    if(window.navigator.onLine){
      window.location.replace(window.location.href + '?cacheBuster=' + Date.now())
    } else {
      window.location.reload();
    }
  }
  

  const links = [
    {
      link: '/',
      label: 'الرئيسية'
    },
    {
      link: '/about/',
      label: 'حول'
    },
    {
      link: '/courses/',
      label: 'الدورات'
    },
  ]


  const { setType } = useContext(AppContext);
  const size = useWindowSize();
  const { pathname } = useLocation();

  console.log(pathname);
  // const [opened, { toggle, close }] = useDisclosure(false);

  const items = links.map((link, index) => (
    <Link key={index} to={link.link} className={
      pathname === link.link ?
        cx(classes.link, classes.linkActive) :
        cx(classes.link, classes.linkInactive)}
      onClick={() => {
        close();
      }}>
      {link.label}
    </Link>
  ));



  return (
    <>
      <header className={classes.header}>
        <Container size={size}>
          <Group justify="space-between" h="100%">
            <Logo layerTwoColor={'#FFF'} layerOneColor={'var(--mantine-color-orange-filled)'} h={3} />

            <Group h="100%" gap={10} visibleFrom="sm">
              {items}
            </Group>


            <ThemeIcon variant="outline" radius="50%" size="lg" onClick={handleRefresh} style={{ cursor: "pointer" }} hiddenFrom="sm">
              <IconRefresh />
            </ThemeIcon>

            <Group visibleFrom="sm">
              {isLogin === true ? <UserAvatar /> : isLogin === false ? <AuthenticationButtons setType={setType} /> : null}
            </Group>



            {/* <Burger opened={opened} onClick={toggle} hiddenFrom="sm" /> */}
          </Group>
        </Container>


        {/*
        <Transition transition="scale-y" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles} hiddenFrom='sm'>
              {items}
              <Divider my="sm" />

              <Group justify="center" grow pb="md" px="md">

                {isLogin === false && <AuthenticationButtons setType={setType} />}

              </Group>

            </Paper>
          )}
        </Transition>
        */}

      </header>
    </>
  );
}


export default Header;




const AuthenticationButtons = ({ setType }) => {

  return (
    <>
      <Link to='/auth' style={{ textDecoration: 'none', color: 'black' }}><Button variant="default" onClick={() => setType('login')}>الدخول</Button></Link>
      <Link to='/auth' style={{ textDecoration: 'none', color: 'white' }}><Button onClick={() => setType('register')}>إنشاء حساب</Button></Link>
    </>
  )

}