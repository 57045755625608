import React from 'react';

import {
  Group,
  Text,
  TitleOrder,
} from '@mantine/core';


  interface AppLogoAttributes {
    layerOneColor: string;
    layerTwoColor: string;
    w?: string;
    h?: TitleOrder;
  }


  const Logo = ( props: AppLogoAttributes) => {

    const { layerOneColor, layerTwoColor, w='60px', h=3} = props;
    

    return (
      <Group gap={3} >
  
      <AppLogo layerTwoColor={layerTwoColor} layerOneColor={layerOneColor} w={w} />
  
      <Text fw={700} fz={22}>دورات</Text>
      </Group>
    )
  }
  
  export default Logo
  
  const AppLogo: React.FC<AppLogoAttributes> = (
    props: AppLogoAttributes
  ) => {
    const { layerOneColor, layerTwoColor, w} = props;
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
        xmlSpace="preserve"
        width={w}
      >
        <g id="BACKGROUND"></g>
        <g id="OBJECTS">
          <g>
            <g>
              <path
                fill={layerOneColor}
                className="st0"
                d="M400.78,343.82l-59.25,23.94l-71.27,28.8c-0.03,0.01-0.06,0.02-0.08,0.03l-0.38,0.15
                     c-0.19,0.08-0.38,0.13-0.58,0.2c-0.56,0.21-1.13,0.42-1.71,0.59c-0.32,0.1-0.65,0.18-0.98,0.26c-0.49,0.13-0.98,0.25-1.48,0.35
                     c-0.38,0.07-0.77,0.13-1.15,0.19c-0.45,0.07-0.9,0.13-1.35,0.18c-0.42,0.04-0.83,0.06-1.25,0.08c-0.43,0.02-0.86,0.04-1.29,0.04
                     h0c-0.73,0-1.46-0.04-2.19-0.1c-0.11-0.01-0.22-0.01-0.33-0.02c-0.63-0.06-1.27-0.16-1.9-0.27c-0.2-0.03-0.41-0.06-0.62-0.1
                     c-0.09-0.02-0.18-0.05-0.27-0.07c-0.48-0.1-0.95-0.22-1.43-0.34c-0.25-0.06-0.51-0.12-0.76-0.2c-0.58-0.17-1.14-0.38-1.71-0.59
                     c-0.19-0.07-0.39-0.12-0.58-0.2L53.08,317.09c-13.35-5.39-20.58-20.46-15.43-33.91c5.24-13.7,20.67-20.37,34.22-14.91
                     l175.22,70.79l2.94,1.19c6.41,2.59,13.57,2.59,19.98,0l158.41-64l33.01-13.34c3.93-1.56,3.54-6.15,0.24-7.92l-182.65-82.36
                     c-12.08-5.45-25.92-5.45-37.99,0L50,258.78c-9.46,3.37-17.23,10.6-21.15,20.32c-7.4,18.32,1.48,39.25,19.8,46.65l197.08,79.63
                     c2.32,1.01,4.76,1.77,7.29,2.27c0.16,0.03,0.31,0.06,0.47,0.09c0.86,0.16,1.73,0.28,2.61,0.38c0.25,0.03,0.51,0.06,0.76,0.08
                     c1.04,0.09,2.08,0.15,3.14,0.15h0h0c4.6,0,9.12-0.88,13.41-2.62l180.54-72.92c8.75-3.54,10.26-6.7,10.26-14.59L400.78,343.82z
                      M260.01,333.85l0.01,0L260.01,333.85L260.01,333.85L260.01,333.85z M52.99,257.86c-0.07,0.02-0.14,0.04-0.21,0.06
                     C52.86,257.9,52.92,257.88,52.99,257.86 M59.08,256.81c-0.2,0.02-0.41,0.05-0.61,0.07C58.67,256.86,58.88,256.83,59.08,256.81
                      M56.05,257.2c-0.15,0.03-0.31,0.06-0.46,0.09C55.74,257.26,55.89,257.23,56.05,257.2"
              />
              <path
                fill={layerOneColor}
                className="st0"
                d="M260,386.39c-2.79,0-5.58-0.41-8.34-1.22c-0.1-0.03-0.19-0.06-0.28-0.1l-190.03-76.9
                     c-1.55-0.63-2.3-2.39-1.67-3.94c0.63-1.55,2.39-2.3,3.94-1.67l189.89,76.84c4.36,1.26,8.62,1.26,13.02,0l183.35-73.93
                     c1.55-0.63,3.32,0.12,3.94,1.68c0.63,1.55-0.12,3.31-1.68,3.94l-183.49,73.99c-0.09,0.04-0.19,0.07-0.28,0.1
                     C265.58,385.99,262.79,386.39,260,386.39"
              />
              <path
                fill={layerOneColor}
                className="st0"
                d="M260,365.3c-2.79,0-5.58-0.41-8.34-1.22c-0.1-0.03-0.19-0.06-0.28-0.1l-190.03-76.9
                     c-1.55-0.63-2.3-2.39-1.67-3.94c0.63-1.55,2.4-2.29,3.94-1.67l189.89,76.85c4.36,1.26,8.63,1.26,13.02,0l183.35-73.93
                     c1.55-0.63,3.31,0.13,3.94,1.68c0.63,1.55-0.12,3.31-1.67,3.94l-183.49,73.99c-0.09,0.04-0.19,0.07-0.28,0.1
                     C265.58,364.89,262.79,365.3,260,365.3"
              />
              <path
                fill={layerOneColor}
                className="st0"
                d="M246.39,78.71L111.9,140.12c-3.38,1.54-3.38,6.35,0,7.89l134.49,61.41c4.06,1.86,8.73,1.86,12.8,0
                     l134.49-61.41c3.38-1.54,3.38-6.35,0-7.89L259.18,78.71C255.12,76.85,250.45,76.85,246.39,78.71"
              />
              <path
                fill={layerTwoColor}
                className="st1"
                d="M252.07,281.24c-59.14,0-103.75-20.13-103.75-46.82v-69.77l94.7,43.24c3.08,1.41,6.37,2.12,9.76,2.12
                     c3.39,0,6.68-0.71,9.77-2.12l93.26-42.59v69.12C355.81,261.12,311.21,281.24,252.07,281.24"
              />
              <path
                fill={layerOneColor}
                className="st0"
                d="M265.69,215.62c-4.08,1.86-8.42,2.81-12.9,2.81c-4.48,0-8.82-0.94-12.9-2.81l-84.03-38.37v58.04
                     c0,21.69,43.07,39.27,96.2,39.27c53.13,0,96.21-17.58,96.21-39.27v-57.38L265.69,215.62z"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  };
  

