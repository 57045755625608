import { Button, Flex, Text, Title } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { IconCircleX } from '@tabler/icons-react';
import React from 'react';

const MessageModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{ title: string; message: string }>) => {
  const { title, message } = innerProps;

  const iconProps = {
    color: 'red',
    size: 100,
    stroke: 1.3,
  };

  return (
      <Flex
        justify='center'
        align='center'
        direction='column'
        px={80}
        py={30}
        gap='sm'
      >
       <IconCircleX  {...iconProps} />
        <Title order={3}>{title}</Title>
        <Text c='dimmed' className='textSize' ta='center'>
          {message}
        </Text>
        <Button onClick={() => context.closeModal(id)} px={25} mt={20}>
          حسنا
        </Button>
      </Flex>
  );
};

export default MessageModal;
