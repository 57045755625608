import '@fontsource/tajawal/400.css';
import '@fontsource/tajawal/500.css';
import '@fontsource/tajawal/700.css';

import { createTheme } from "@mantine/core";

export const theme = createTheme({
  fontFamily: "Tajawal, sans-serif",
  primaryColor: 'orange'
});
