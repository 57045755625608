import React from "react";

import { ContainerProps, GroupProps, Container as MantineContainer, Group as MantineGroup } from '@mantine/core';
import { useWindowSize } from '../../hooks/useWindowSize';


const Container = (props: ContainerProps) => {
  return <MantineContainer size={useWindowSize()} {...props} />;
};


const Group = (props: GroupProps) => {
  return <MantineGroup grow={useWindowSize() == "xs" ? true : false} {...props} />;
};

export { Container, Group };
