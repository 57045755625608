import { Flex } from "@mantine/core";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import React from "react";

const OfflineIndicator = () => {

  return (
        <Flex
          justify="center"
          align="center"
          m={0}
          py={8}
          c="white"
          gap={12}
          style={{
            width: "100%",
            background: "var(--mantine-color-red-8)",
            fontSize: "1.2rem",
            zIndex: 10,
          }}
        >
          <IconAlertTriangleFilled />
          الموقع غير متصل بالانترنت
          <IconAlertTriangleFilled />
        </Flex>
  );
};

export default OfflineIndicator;
