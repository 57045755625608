module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wp.firas.ly/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"theme_color":"#f69435","background_color":"#f69435","display":"standalone","scope":"/","start_url":"/","name":"Dawrat","short_name":"dawrat","icons":[{"src":"/appIcons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"/appIcons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/appIcons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"maskable"},{"src":"/appIcons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"/appIcons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*"],"runtimeCaching":[{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkOnly"}],"skipWaiting":true}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
