import { Flex, Group, Paper, Text } from "@mantine/core";
import { useOs } from '@mantine/hooks';
import { useLocation } from '@reach/router';
import { IconBook, IconHome, IconUserCircle } from "@tabler/icons-react";
import cx from "clsx";
import { Link } from "gatsby";
import React from "react";
import { Container } from "../MantineRefactor/MantineRefactor";
import * as classes from "./BottomNav.module.css";


interface BottomNavProp {
  hiddenFrom: string;
}


function BottomNav({ hiddenFrom }: BottomNavProp) {
  const os = useOs();

  const links = [
    {
       icon: IconHome,
       link: '/',
       label: 'الرئيسية'
    },
    {
      icon: IconBook,
      link: '/courses/',
      label: 'الدورات'
    },
    {
      icon: IconUserCircle,
      link: '/auth/',
      label: 'الحساب'
    },
  ]

  const {pathname}  = useLocation();

  const items = links.map((link, index) => (
    <Link key={index} to={link.link} className={
        pathname === link.link ?
        cx(classes.link, classes.linkActive) : 
        cx(classes.link, classes.linkInactive)}>

      <Flex direction="column" align="center" gap={4}>
        <link.icon stroke={1.2} />
        <Text fz={"sm"}>{link.label}</Text>
      </Flex>
      
    </Link>
  ));

  return (
    <Paper shadow="xl" withBorder hiddenFrom={hiddenFrom} pt={10} pb={os === 'ios' ? 25 : 15}>
      <Container>
        <Group justify="center" gap={45}>
          {items}
        </Group>
      </Container>
    </Paper>
  );
}

export default BottomNav;
