import React from "react";

import {
  ActionIcon,
  Group,
  Text,
} from "@mantine/core";
import {
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandYoutube,
} from "@tabler/icons-react";
import Logo from "../Logo/Logo";

import { Container } from "../MantineRefactor/MantineRefactor";
import * as classes from './Footer.module.css';

function Footer() {


  return (
    <Container className={classes.footer}>
      <div className={classes.inner}>
      <Logo layerTwoColor={'#FFF'} layerOneColor={'var(--mantine-color-orange-filled)'} h={3}/>

        <Text c="dimmed" size="sm">
        جميع الحقوق محفوظة. فراس الدويني 2023 ©
        </Text>

        <Group gap="xs" align="right" wrap="nowrap">
          <ActionIcon size="lg" variant="default" radius="xl" aria-label="twitter">
            <IconBrandTwitter size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" variant="default" radius="xl" aria-label="youtube">
            <IconBrandYoutube size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" variant="default" radius="xl" aria-label="instagram">
            <IconBrandInstagram size="1.05rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </div>
    </Container>
  );
}

export default Footer;
