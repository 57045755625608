import axios from 'axios';
import Cookies from 'js-cookie';
import { deleteAllDataFromObjectStore, storeAsSingleKey } from './indxedDB';
// const isOnline = window.navigator.onLine;

const authenticateUser = async (username: string, password: string) => {
  try {
    console.log("#1");
    const response = await axios.post(`${process.env.WORDPRESS_URL}/wp-json/aam/v2/authenticate`, {
      username: username,
      password: password,
      issueJWT: true
    });

    console.log("#2");
    await storeAsSingleKey(response.data.user.data, "userData", "user");
    console.log("#3");
    Cookies.set('jwtToken', response.data.jwt.token, { expires: new Date(response.data.jwt.token_expires * 1000) });
    console.log("#4");

    return {code: 'authentication_success', data: response.data};

  } catch (error) {

    if (error.message === "Network Error") {
      return {code: 'network_error', data: {}};
    }

    if (error.response && error.response.data !== undefined) {
      return {code: error.response.data.code , data: {}};
    } else
      throw error;
  }

};


const registerUser = async (email, username, password, phone, city) => {
  try {
    const response = await axios.post(`${process.env.WORDPRESS_URL}/wp-json/wp/v2/users/register`, {
      email: email,
      username: username,
      password: password,
      phone: phone,
      city: city
    });

    await storeAsSingleKey(response.data.user.data, "userData", "user");
    Cookies.set('jwtToken', response.data.jwt.token, { expires: new Date(response.data.jwt.token_expires * 1000) });

    return {code: 'authentication_success', data: response.data};

  } catch (error) {
    if (error.message === "Network Error") {
      return {code: 'network_error', data: {}};
    } else
      throw error;
  }

};



const validateJwtToken = async (jwtToken: string) => {
  try {
    let response;
    if(window.navigator.onLine){
    response = await axios.post(`${process.env.WORDPRESS_URL}/wp-json/aam/v2/jwt/validate`, {
      jwt: jwtToken
    });
  } else return true

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response && error.response.data !== undefined) {
      throw error.response.data;
    } else {
      throw error
    };

  }


};

const isLoggedIn = async () => {
  const jwtToken = Cookies.get('jwtToken');
  if (jwtToken) {
    try {
      return await validateJwtToken(jwtToken);
    } catch (error) {
      return false
    }
  } else {
    return false;
  }
};


const logOutUser = async () => {
  // Revoke JWT token
  const jwtToken = Cookies.get('jwtToken');

  try {
    if (jwtToken) {
      await axios.post(`${process.env.WORDPRESS_URL}/wp-json/aam/v2/jwt/revoke`, {
        jwt: jwtToken
      });
    }

    // Clean IndexedDB
    await deleteAllDataFromObjectStore("userData", "user");

    // Remove JWT token from cookies
    Cookies.remove('jwtToken');

  } catch (error) {
    console.error('Failed to revoke JWT token:', error);
  }
};


export { authenticateUser, isLoggedIn, logOutUser, registerUser };
