import { Button, Dialog, Group, Text } from "@mantine/core";
import { navigate } from "gatsby";
import moment from "moment";
import React, { useEffect, useState } from "react";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

const provideButton = (): boolean => {
  if (typeof window === "undefined") {
    // Server-side rendering, localStorage is not available
    return false;
  }

  const dismissed = localStorage.getItem("pwa_dismissal");
  const dismissedMoment = moment(dismissed);
  return !dismissed || dismissedMoment.isBefore(moment());
};

const PwaPrompt = (): JSX.Element => {
  const suspend = 2;
  const acceptedUri = "/";
  const dismmissedUri = "/";

  const [showButton, setShowButton] = useState<boolean>(provideButton());
  const [prompt, setPrompt] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleStorePrompt = (e: Event): void => {
      e.preventDefault();
      const beforeInstallPromptEvent = e as BeforeInstallPromptEvent;
      if (showButton) setPrompt(beforeInstallPromptEvent);
    };

    if (typeof window !== "undefined") {
      // Add event listener only in the client-side
      window.addEventListener("beforeinstallprompt", handleStorePrompt);
    }

    return () => {
      if (typeof window !== "undefined") {
        // Remove event listener only in the client-side
        window.removeEventListener("beforeinstallprompt", handleStorePrompt);
      }
    };
  }, [showButton]);

  const handlePrompt = (): void => {
    if (prompt) {
      setShowButton(false);
      prompt.prompt();
      setPrompt(null);

      prompt.userChoice.then((result) => {
        if (result.outcome === "accepted" && acceptedUri) navigate(acceptedUri);

        if (result.outcome === "dismissed") {
          const dismissalDate = moment().add(suspend, "days");
          const dismissalDateString = dismissalDate.format();
          if (typeof window !== "undefined") {
            // Use localStorage only in the client-side
            localStorage.setItem("pwa_dismissal", dismissalDateString);
          }
          if (dismmissedUri) navigate(dismmissedUri);
        }
      });
    }
  };

  const handleCloseDialog = () => {
    setShowButton(false);
    setPrompt(null);
    const dismissalDate = moment().add(suspend, "days");
    const dismissalDateString = dismissalDate.format();
    if (typeof window !== "undefined") {
      // Use localStorage only in the client-side
      localStorage.setItem("pwa_dismissal", dismissalDateString);
    }
  };

  return (
    <>
      <Dialog
        opened={showButton && prompt !== null}
        withCloseButton
        size="lg"
        radius="md"
        onClose={handleCloseDialog}
      >
        <Group>
          <Text size="sm" fw={500}>
            جرب ثبت التطبيق في هاتفك
          </Text>

          <Button onClick={handlePrompt} aria-label="close">تثبيت</Button>
        </Group>
      </Dialog>
    </>
  );
};

export default PwaPrompt;
