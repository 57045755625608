import axios from 'axios';
import Cookies from 'js-cookie';

interface Submission {
  key: number;
  value: {
    email: string;
    fullname: string;
    note: string;
    bookAt: string;
  };
}

const sendSubmissionToWordPress = async (submissions: Submission) => {

  
    const jwtToken = Cookies.get('jwtToken');

    if(!jwtToken) {
      return 401;
    }
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwtToken}`,
    };
  
      try {
  
        const response = await axios.post(
          `${process.env.WORDPRESS_URL}/wp-json/pwa/v1/form/${process.env.FORM_ID}`,
           submissions ,
          { headers }
        );
  
        return {status: response.status, keys: response.data.successKeys};
      } catch (error)  {
        if (error.response && error.response.status !== undefined) {
          return error.response.status;
        } else
          throw error;
      }
  };


  export { sendSubmissionToWordPress };
