import { openDB } from 'idb';

const getAllKeysAndValues = async (db_name: string, store_name: string) => {

  const data = [];

  try {
    const db = await openDB(db_name);

    if (!db) {
      return [];
    }

    if (!db.objectStoreNames.contains(store_name)) {
      return [];
    }
    
    const tx = db.transaction(store_name, 'readonly');


    if (!tx) {
      return [];
    }

    const store = tx.objectStore(store_name);


    if (!store) {
      return [];
    }

    let cursor = await store.openCursor();
    if (!cursor) return [];

    do {
      const key = cursor.key;
      const value = cursor.value;

      data.push({
        key,
        value
      });

    } while (cursor = await cursor.continue());

    db.close();

  } catch (err) {
    console.error(err);
    return [];
  }
  return data;
};




const storeAsSingleKey = async (
  data: { [key: string]: any },
  db_name: string,
  store_name: string
): Promise<void> => {

  console.log("2.1");

  try {
    let db = await openDB(db_name);

    console.log("2.2");

    if (!db.objectStoreNames.contains(store_name)) {
      const newversion = db.version + 1;
      db.close();
      db = await openDB(db_name, newversion, {
        upgrade: (db) => {
          db.createObjectStore(store_name)
        },
      });
    }

    const tx = db.transaction(store_name, 'readwrite');
    const store = tx.objectStore(store_name);

    for (let key in data) {
      store.put(data[key], key);
    }

    console.log("2.3");
    tx.done;
    db.close();
  } catch (error) {
    console.error('An error occurred during database operations:', error);
    throw error;
  }
};


const storeAsMultiKey = async (
  data: { [key: string]: any },
  db_name: string,
  store_name: string
): Promise<void> => {

  try {
    let db = await openDB(db_name);

    if (!db.objectStoreNames.contains(store_name)) {
      const newversion = db.version + 1;
      db.close();
      db = await openDB(db_name, newversion, {
        upgrade: (db) => {
          db.createObjectStore(store_name, { keyPath: null, autoIncrement: true })
        },
      });
    }

    const tx = db.transaction(store_name, 'readwrite');
    const store = tx.objectStore(store_name);

    store.put(data)

    tx.done;
    db.close();
  } catch (error) {
    console.error('An error occurred during database operations:', error);
    throw error;
  }
};



const deleteAllDataFromObjectStore = async (db_name: string, store_name: string) => {

  const db = await openDB(db_name);
  const tx = db.transaction(store_name, 'readwrite');
  const store = tx.objectStore(store_name);

  await store.clear();

  tx.done;
  db.close();
};


const addToDB = async (
  db_name: string,
  store_name: string,
  key: string,
  value: string
): Promise<void> => {


  try {
    let db = await openDB(db_name);

    if (!db.objectStoreNames.contains(store_name)) {
      const newversion = db.version + 1;
      db.close();
      db = await openDB(db_name, newversion, {
        upgrade: (db) => {
          db.createObjectStore(store_name)
        },
      });
    }

    const tx = db.transaction(store_name, 'readwrite');
    const store = tx.objectStore(store_name);


    store.put(value, key);

    tx.done;
    db.close();
  } catch (error) {
    console.error('An error occurred during database operations:', error);
    throw error;
  }
};


const deleteDataFromObjectStore = async (db_name: string, store_name: string, keysToDelete: number[]): Promise<void> => {
  try {
    const db = await openDB(db_name);
    const tx = db.transaction(store_name, "readwrite");
    const store = tx.objectStore(store_name);

    for (const key of keysToDelete) {
      if (await store.get(key)) {
        await store.delete(key);
      }
    }

    await tx.done;
    db.close();
  } catch (error) {
    console.error("An error occurred during database operations:", error);
    throw error;
  }
};


const getValueFromObjectStore = async (db_name: string, store_name: string, key: string): Promise<any | undefined> => {
  try {
    const db = await openDB(db_name);
    const tx = db.transaction(store_name, "readonly");
    const store = tx.objectStore(store_name);

    const value = await store.get(key);

    await tx.done;
    db.close();

    return value;
  } catch (error) {
    console.error("An error occurred during database operations:", error);
    throw error;
  }
};


const countSubmissions = async () => {

  const db_name = 'userData';
  const store_name = 'submissions';
 
  try {
    const db = await openDB(db_name);

    if (db.objectStoreNames.contains(store_name)){

      const tx = db.transaction(store_name, "readonly");
      const store = tx.objectStore(store_name);
      const count = store.count();
      await tx.done;
      db.close();
      return count;

      
    } else {
      db.close();
      return 0;
    } 

  } catch (error) {
    console.error("An error occurred during database operations:", error);
    throw error;
  }
};



export {
  addToDB,
  countSubmissions, deleteAllDataFromObjectStore,
  deleteDataFromObjectStore, getAllKeysAndValues, getValueFromObjectStore, storeAsMultiKey, storeAsSingleKey
};
