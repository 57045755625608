import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import React from "react";
import { MantineProvider } from "@mantine/core";
import { theme } from "./src/theme";
import Layout from "./src/components/Layout/Layout";
import { AppProvider } from "./src/components/AppContext/AppContext";
import MessageModal from './src/components/MessageModal/MessageModal';
import { ModalsProvider } from '@mantine/modals';
import { isLoggedIn } from "./src/utils/auth";
import Cookies from "js-cookie";
import { navigate } from "gatsby";

const modals = {
  demonstration: MessageModal,
};

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof modals;
  }
}



export const wrapPageElement = ({ element }) => {
  return (
    <MantineProvider theme={theme}>
      <AppProvider>
      <ModalsProvider  modals={modals} >
      <Layout>{element}</Layout>
      </ModalsProvider>
      </AppProvider>
    </MantineProvider>
  );
};


export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'تم تحديث هذا التطبيق.' +
      'هل تريد إعادة التحميل لعرض أحدث إصدار؟'
  );
  if (answer === true) {
    window.location.replace(window.location.href + '?cacheBuster=' + Date.now());
  }
};



export function onRouteUpdate({ location }) {

  const checkLogin = async () => {
    if (window.navigator.onLine){
      const OnlineLoggedIn: boolean = await isLoggedIn();
      if(!OnlineLoggedIn){
        Cookies.remove('jwtToken');
      }
    }
  }
  const getToken = Cookies.get('jwtToken');
  const loggedIn = !!getToken;

    if(location.pathname === '/account/' || location.pathname === '/auth/'){
      checkLogin();

      if (loggedIn === false && location.pathname === `/account/`) {
        navigate(`/auth/`);
      } else if (loggedIn === true && location.pathname === `/auth/`) {
        navigate(`/account/`);
      }
    }

  };




export function onServiceWorkerActive({ location }) {

  alert('الموقع الآن يعمل اوفلاين')
  };
