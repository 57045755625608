import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useState } from 'react';


export const AppContext = createContext<any>(null);


export const AppProvider = ({ children }: { children: any }) => {
  const [type, setType] = useState('login');
  const coursesQuery = useStaticQuery(query);

  const coursedata = transformedData(coursesQuery.allWpPost.edges);

  return (
    <AppContext.Provider value={{ type, setType, coursedata}}>
      {children}
    </AppContext.Provider>
  );
};



export const query = graphql`
query {
  allWpPost {
    edges {
      node {
        slug
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  quality: 90
                )
              }
            }
          }
        }
        course {
          discount
          duration
          featured
          price
          instructor
          reviews
          seats
        }
        title
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
}
`;



const transformedData = (originalData: any) => {

  const removeHtml = (text) => {

    return text.replace(/<[^>]+>|\n|\t|\r|\//g, "");
  }

  return originalData.map((item) => {
    const {
      slug,
      content,
      title,
      categories: {
        nodes: [{ name: category }],
      },
      course: { seats, instructor, reviews, discount, price, featured, duration },
      featuredImage: {
        node: {
          localFile: { childImageSharp: { gatsbyImageData: photo } },
        },
      }
    } = item.node;
  
    const parsedReviews = JSON.parse(removeHtml(reviews));
  
    return {
      slug,
      content: removeHtml(content),
      title,
      category,
      seats,
      instructor,
      reviews: parsedReviews,
      discount,
      duration,
      price,
      photo,
      featured,
    };
  })


}