import { Box } from "@mantine/core";
import React, { useEffect } from "react";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import { sendSubmissionToWordPress } from "../../utils/handleData";
import { deleteDataFromObjectStore, getAllKeysAndValues } from "../../utils/indxedDB";
import BottomNav from "../BottomNav/BottomNav";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import InstallPWA from "../InstallPWA/InstallPWA";
import OfflineIndicator from "../OfflineIndicator/OfflineIndicator";

const Layout = ({ children }: { children: JSX.Element }) => {

  const isOnline = useOnlineStatus();

  const sendToWordPress = async () => {
    const data = await getAllKeysAndValues("userData", "submissions");
    if (data.length === 0) return;
    const response = await sendSubmissionToWordPress(data);
    if (response && response.status === 200) {
      await deleteDataFromObjectStore('userData', 'submissions', response.keys)
    }
  }

  useEffect(() => {
    const currentUrl = window.location.href;
    const cacheBusterIndex = currentUrl.indexOf('?cacheBuster');
    if (cacheBusterIndex !== -1) {
      const cleanedUrl = currentUrl.slice(0, cacheBusterIndex);
      window.history.replaceState({}, document.title, cleanedUrl);
    }
  }, []);

  useEffect(() => {
    const sendToWordPressInLayout = async () => {
      if (isOnline) await sendToWordPress();
    };
    sendToWordPressInLayout();
  }, [isOnline]);



  return (
    <div>

      <Header />
      {children}
      <Footer />
      <Box style={{ zIndex: 99}} mt={20} pos='sticky' left={0} right={0} bottom={0}>
        {!isOnline ? <OfflineIndicator /> : null}
        <BottomNav hiddenFrom="sm" />
        <InstallPWA />
      </Box>
    </div>
  )
}

export default Layout;