import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Group,
  Menu,
  Text,
  UnstyledButton,
  rem,
} from '@mantine/core';
import {
  IconChevronDown,
} from '@tabler/icons-react';
import cx from 'clsx';
import * as classes from './UserAvatar.module.css';

import { IconLogout, IconUserCircle } from '@tabler/icons-react';
import { Link, navigate } from 'gatsby';
import { logOutUser } from '../../utils/auth';
import { getValueFromObjectStore } from '../../utils/indxedDB';






const UserAvatar = () => {
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [userName, setUserName] = useState('');
  const [user, setUser] = useState({
    name: userName,
    image: 'https://secure.gravatar.com/avatar/ce2d5dce4b023597ceaaa075f939b376?s=48&d=mm&r=g',
  });

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const fetchedUserName = await getValueFromObjectStore('userData', 'user', 'display_name');
        const resolvedUserName = fetchedUserName ?? 'زائر';
        setUserName(resolvedUserName);
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
    };

    fetchUserName();
  }, []);

  useEffect(() => {
    setUser((prevState) => ({
      ...prevState,
      name: userName,
    }));
  }, [userName]);


  const handleLogoutUser = async () => {
    await logOutUser();
    navigate('/', {replace: true});
  }



  return (
          <Menu
            width={260}
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
              >
                <Group gap={7}>
                  <Avatar src={user.image} alt={user.name} radius="xl" size={20} />
                  <Text fw={500} size="sm" lh={1} mr={3}>
                    {user.name}
                  </Text>
                  <IconChevronDown style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                    <IconUserCircle style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                }
                component={Link}
                to='/account/'
                replace={true}
              >
                عرض الحساب
              </Menu.Item>
              <Menu.Item
                leftSection={
                    <IconLogout style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                }
                component={'button'}
                onClick={handleLogoutUser}
              >
                تسجيل الخروج
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
  );
}


export default UserAvatar;